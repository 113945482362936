@layer base {
  .main {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: var(--sk-space-32);
}

.title::before {
  content: attr(data-subtitle);
  display: inline-block;
  width: 100%;
  color: var(--sk-color-green-500);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  text-transform: uppercase;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 432px;
  margin-top: var(--sk-space-64);
}

.cover {
  min-height: 600px;
}

.footer {
  display: inline-flex;
  gap: var(--sk-space-20);
  margin-top: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .main {
    flex-direction: row;
  }

  .content {
    justify-content: center;
    max-width: 312px;
    margin-top: 0;
  }
}

@media (--sk-large-viewport) {
  .section {
    justify-content: flex-start;
  }

  .content {
    max-width: 364px;
    padding-left: 100px;
  }
}

}