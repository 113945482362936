@layer base {
  .main {
  display: block;
  padding-bottom: var(--sk-space-64);
}

.cover {
  height: 30rem;
}

.section {
  box-sizing: border-box;
  padding: var(--sk-space-64) var(--sk-space-24);
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
}

.section::after {
  display: none;
}

.content {
  z-index: var(--z-up-in-the-current-stacking-context);
  max-width: 435px;
  margin: 0 auto;
  padding: 0 var(--sk-space-24);
}

.title {
  margin-bottom: var(--sk-space-32);
}

.title::before {
  content: attr(data-subtitle);
  display: inline-block;
  width: 100%;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  text-transform: uppercase;
}

.footer {
  display: inline-flex;
  gap: var(--sk-space-16);
  margin-top: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .main {
    display: flex;
  }

  .section {
    flex-basis: 57%;
    padding: var(--sk-space-64) 0;
  }

  .cover {
    flex-basis: 43%;
    height: auto;
    background-position: 45% 40%;
  }

  .content {
    max-width: 300px;
    padding: 0;
  }
}

@media (--sk-large-viewport) {
  .cover {
    height: 42.5rem;
    background-position: 150% 0;
    background-size: 580px auto;
  }

  .section {
    position: relative;
  }

  .section::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 425px solid;
    border-left: 530px solid rgb(0 0 0 / 0%);
  }

  .content {
    margin: 0 0 0 136px;
  }
}

}