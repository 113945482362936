@layer base {
  .wrapper {
    background-color: var(--sk-color-grey-050);
}

.main {
    --layout-size: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    max-width: var(--layout-size);
    padding: var(--sk-space-64) var(--sk-space-16);
    color: var(--sk-color-white);
}

.cardsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--sk-space-16);
}

.card {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    border-radius: var(--sk-radius-12);
    background-color: white;
    color: black;
}

.cardImage{
    width: 100%;
    object-fit: cover;
}

.cardTextContent {
    display: flex;
    z-index: var(--z-up-in-the-current-stacking-context);
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: var(--sk-space-24) var(--sk-space-16);
}

.cardDescription {
    padding: var(--sk-space-8) 0 var(--sk-space-12) 0;
}

.cardButton {
    width: 100%;
    font-size: var(--sk-font-size-1);
    line-height: var(--sk-line-heights-4);
}

.cardPicto {
    position: absolute;
}

.cardPictoBuilding {
    right: -30px;
    bottom: -43px;
}

.cardPictoArrow {
    right: -30px;
    bottom: -50px;
    transform: rotate(-20deg);
}

@media (--sk-medium-viewport) {
    .main {
        --layout-size: 77rem;
        max-width: var(--layout-size);
        margin: auto;
        padding: var(--sk-space-64) var(--sk-space-40);
    }   

    .cardsWrapper {
        flex-direction: row;
    }

    .card {
        flex-direction: row;
    }

    .cardTextContent {
        padding: var(--sk-space-32) var(--sk-space-16);
    }

    .cardButton {
        width: auto;
    }

    .cardImage{
        width: 150px;
        height: 100%;
    }
}

@media (--sk-large-viewport) {
    .main {
        --layout-size: 77rem;
        width: 100%;
        max-width: var(--layout-size);
        margin: auto;
        padding: var(--sk-space-64) var(--sk-space-40);
    }   

    .cardTextContent {
        padding: var(--sk-space-24);
    }

    .cardImageContainer {
        display: flex;
        width: 100%;
        max-width: 235px;
    }

    .cardImage {
        width: inherit;
    }
}
}