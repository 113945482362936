@layer base {
  .main {
  background-color: var(--sk-color-black);
}
/* ******************* */
/* NEW HOME PAGE CODE  */
/* ******************* */

.newMain {
  width: 100%;
}

@media ( width >= 64rem ) {
  /* --large-viewport */
  .newMain {
    margin: calc(var(--sk-space-64) + var(--sk-space-40)) auto 0;
  }
}

}