@layer base {
  .main {
  display: flex;
  position: relative;
  background-color: var(--sk-color-white);
}

.main__isBlack {
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
}

.wrapper {
  box-sizing: border-box;
  padding: 0 var(--sk-space-24);
}

.wrapper__withMaxWidth {
  width: 100%;
  max-width: 1160px;
  margin: var(--sk-space-64) auto;
}

.wrapper__withBackground {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media (--sk-medium-viewport) {
  .wrapper {
    display: grid;
    flex: 1 0 50%;
  }

  .wrapper__isSmall {
    flex-basis: 50%;
  }

  .wrapper__withMaxWidth {
    flex-basis: 100% !important;
  }
}

@media (--sk-large-viewport) {
  .wrapper {
    flex-basis: 58%;
  }

  .wrapper__isSmall {
    flex-basis: 42%;
  }
}

}