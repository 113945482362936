@layer base {
  .list {
  display: none;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 var(--sk-space-20);
  margin: var(--sk-space-24) 0 0 0;
  padding: 0;
  list-style: none;
}

.radio {
  display: none;
}

.label {
  cursor: pointer;
}

.tabs {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  margin: var(--sk-space-12) 0 0 0;
  padding: 0;
  list-style: none;
  gap: var(--sk-space-24);
}

.tab {
  padding: var(--sk-space-16) 0;
  border-bottom: var(--sk-space-4) solid transparent;
  cursor: pointer;
}

.tab:hover,
.secondaryTab:checked ~ .tabnav .tab:nth-child(1):hover {
  border-color: var(--sk-color-grey-200);
}

.tab:nth-child(1),
.primaryTab:checked ~ .tabnav .tab:nth-child(1),
.secondaryTab:checked ~ .tabnav .tab:nth-child(2) {
  border-color: var(--sk-color-black);
}

.secondaryTab:checked ~ .tabnav .tab:nth-child(1) {
  border-color: transparent;
}

.list:nth-child(1),
.primaryTab:checked ~ .content .list:nth-child(1),
.secondaryTab:checked ~ .content .list:nth-child(2) {
  display: grid;
}

.primaryTab:checked ~ .content .list:nth-child(2),
.secondaryTab:checked ~ .content .list:nth-child(1) {
  display: none;
}

@media (--sk-medium-viewport) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--sk-large-viewport) {
  .list {
    grid-template-columns: repeat(4, 1fr);
  }
}

}