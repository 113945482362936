@layer base {
  .main {
	padding: 0 var(--sk-space-16);
	padding-top: var(--sk-space-80);
}

.titleWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: var(--sk-space-80);
	text-align: center;
}

.arrow {
	margin: var(--sk-space-8) auto 0;
}

.block {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 90px;
}

.blockImage {
	width: 100%;
	max-width: 382px;
	height: 100%;
	max-height: 286px; 
	object-fit: contain;
}

.blockTitle {
	margin-top: var(--sk-space-40);
}

.blockDescription {
	padding-top: var(--sk-space-16);
}

.blockButton {
	width: 100%;
	margin-top: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
	.main {
		padding-top: 104px;
	}

	.titleWrapper {
		flex-direction: row;
		align-items: center;
		margin: 0 145px 104px;
	}

	.arrow {
		width: 98px;
		height: 66px;
		margin: 0;
	}
	
	.block {
		flex-direction: row;
	}

	.blockTitle {
		margin-top: 0;
	}

	.blockButton {
		width: auto;
	}

	.blockTextContent {
		width: 305px;
	}

	.primaryText {
		margin-left: var(--sk-space-80);
	}

	.secondaryText{
		margin-right: var(--sk-space-80);
	}

	.pictureOnTheRightOnMediumLargeScreen {
		order: 2;
	}

	.textOnTheLefttOnMediumLargeScreen {
		order: 1;
	}
}

@media (--sk-large-viewport) {
	.titleWrapper {
		max-width: 730px;
		margin: 0 auto 104px;
	}

	.arrow {
		width: 130px;
		height: 88px;
		margin: 0;
	}
	
	.blockTextContent {
		width: 400px;
	}

	.blockButton {
		width: auto;
	}

	.blockImage {
		max-width: 608px;
		max-height: 400px; 
	}

	.primaryText {
		margin-left: 120px;
	}

	.secondaryText{
		margin-right: 120px;
	}
}
}