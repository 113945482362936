@layer base {
  .content {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 420px;
  margin: var(--sk-space-64) auto;
}

.text {
  margin-bottom: var(--sk-space-24);
  font-size: var(--sk-typography-display-2-font-size);
  line-height: var(--sk-typography-display-2-line-height);
}

.button {
  align-self: flex-start;
  order: -1;
  margin-top: var(--sk-space-48);
}

.cover {
  display: none;
}

@media (--sk-medium-viewport) {
  .content {
    max-width: 370px;
  }

  .cover {
    display: block;
    background-position: center right;
  }
}

@media (--sk-large-viewport) {
  .content {
    justify-self: flex-end;
    width: 100%;
    max-width: 564px;
    margin: 160px 0;
    padding: 0 136px 0 0;
  }

  .text {
    margin-bottom: var(--sk-space-24);
    font-size: 60px;
    line-height: 75px;
  }
}

}