@layer base {
  .main {
  background-color: var(--sk-color-grey-025);
}

.wrapper {
  display: grid;
  gap: var(--sk-space-32);
}

.card {
  position: relative;
  box-sizing: border-box;
  min-height: 210px;
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-white);
  text-align: left;
}

.card::before {
  content: "";
  display: block;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  clip-path: polygon(0 0, 30% 0, 85% 100%, 0 100%);
  width: 160px;
  height: 100%;
  float: left;
  overflow: hidden;
  border-radius: inherit;
  background: no-repeat 90% 0;
  background-image: inherit;
  background-size: cover;
  shape-outside: polygon(0 0, 45% 0, 100% 100%, 0 100%);
}

.card::after {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--sk-color-white);
}

.content {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  box-sizing: border-box;
  height: 100%;
  padding: var(--sk-space-24);
}

.text {
  max-width: 90%;
  margin: var(--sk-space-12) 0 var(--sk-space-24) 0;
  color: var(--sk-color-grey-600);
}

.link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  left: 0;
}

@media (--sk-medium-viewport) {
  .card::before {
    clip-path: polygon(0 0, 60% 0, 95% 100%, 0 100%);
    shape-outside: polygon(0 0, 75% 0, 100% 100%, 0 100%);
  }
}

@media (--sk-large-viewport) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .card::before {
    width: 280px;
    clip-path: polygon(0 0, 30% 0, 85% 100%, 0 100%);
    background-position: 0 70%;
    background-size: 90%;
    shape-outside: polygon(0 0, 45% 0, 100% 100%, 0 100%);
  }
}

}